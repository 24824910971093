
import { mdiClose, mdiPaperclip } from "@mdi/js"
import { postArticle } from '@/api/article.service.js'
import { uploadDocuments } from '@/api/document.service.js'
import featureComponent from '@/mixins/featureComponent'
import FileMin from '@/components/base/FileMin.vue'

export default {
  name: 'UrlComponent',
  components: {
    FileMin,
  },
  mixins: [featureComponent],
  data: () => ({
    url: '',
    isLoading: false,
    mdiClose,
    mdiPaperclip,
  }),
  computed: {
    listItems() {
      if (this.$attrs.multiple) {
        return this.model || []
      } else {
        return this.model ? [this.model] : []
      }
    },
  },
  methods: {
    onAdd() {
      if (!this.url || this.isLoading) {
        return;
      }

      if (Array.isArray(this.model)) {
        if (this.model.some((s) => s?.url === this.url)) {
          return;
        }
      } else if (this.model === this.url) {
        return;
      }
      this.isLoading = true;
      try {
        this.$api.run(postArticle, { url: this.url })
                  .then((res) => {
                    const source = { ...res, type: "article" };
                    this.$store.dispatch('addSource', source);
                    this.modelInsert(source)
                    this.isLoading = false;
                  })
                  .catch(() => {
                    // this is what we get for wrapping axios
                    this.$api.run(uploadDocuments, { url: this.url })
                      .then((res) => {
                        const source = { ...res, type: "document" };
                        this.$store.dispatch('addSource', source);
                        this.modelInsert(source)
                        this.isLoading = false;
                      })
                      .catch((err) => {
                        console.warn("document post err: ", err)
                        this.isLoading = false;
                      })
                  })
      } catch(err) {
        console.warn("somehow I still go here")
      } finally {
        if (this.$attrs.multiple) {
          this.url = ''
        }
      }
    },
    modelInsert(val) {
      if (this.$attrs.multiple) {
        if (Array.isArray(this.model)) {
          this.model = [...this.model, val];
          return
        } else if (this.model) {
          this.model = [this.model, val]
        } else {
          this.model = [val];
        }
        return;
      }
      this.model = val;
    },
    onRemove(index) {
      if (Array.isArray(this.model)) {
        this.model.splice(index, 1)
      } else {
        this.model = null
        this.url = ''
      }
    },
  },
}
