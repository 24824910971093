
import { mdiPlus, mdiFileDocument, mdiWeb, mdiChevronRight, mdiChevronLeft, mdiClose } from '@mdi/js'
import { uploadDocuments, getDocuments } from '@/api/document.service.js'
import Url from '@/components/feature/components/Url.vue'
import featureComponent from '~/mixins/featureComponent'
export default {
    name: 'SourceInputModal',
    components: {
        Url
    },
    mixins: [featureComponent],
    props: {
        multiple: {
            type: Boolean,
            default: false,
        },
        types: {
            type: Array,
            default: () => ['article', 'document', 'paper'],
        },
    },
    data: () => ({
        modal: false,
        isLoading: false,
        showUrlInput: false,
        icons: {
            mdiPlus,
            mdiFileDocument,
            mdiWeb,
            mdiChevronRight,
            mdiChevronLeft,
            mdiClose
        },
    }),
    computed: {
        model: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    methods: {
        submit() {
            this.modal = false
            this.$emit('submit', this.model)
        },
        upload() {
            this.$refs.input.click()
        },
        goBack() {
            this.showUrlInput = false
        },
        openModal() {
            this.modal = true
        },
        async onChange(value) {
            const files = Array.from(value.target?.files)
            // const file = Array.isArray(files) ? files[0] : files
            this.isLoading = true
            try {
                for (const file of files) {
                    // file size needs to be lower than 50MB
                    if (file.size > 50 * 1024 * 1024) {
                        this.$store.dispatch('setError', this.$t('labels.file_too_large'))
                        continue
                    }
                    const formData = new FormData()
                    formData.append('file', file, file.name)

                    await this.$api.run(uploadDocuments, formData)
                        .then((res) => {
                            const source = {
                                ...res,
                                type: "document",
                            }
                            this.$store.dispatch('addSource', source)
                            if (this.multiple) {
                                if (Array.isArray(this.model)) {
                                    this.model = [...this.model.filter((s) => s.uuid !== source.uuid ), source]
                                } else {
                                    this.model = [source]
                                }
                            } else {
                                this.model = source
                            }
                        })
                        .catch((err) => {
                            console.warn("doc upload err: ", err)
                            this.onExists(file.name)
                        })
                }
            } catch (err) {
                console.warn("doc upload err: ", err)
                this.onExists(file.name)
            } finally {
                this.isLoading = false
                this.modal = false
            }
        },
        async onExists(value) {
            // list all files with documents.get
            await this.$api.run(getDocuments)
            .then((res) => {
                res.forEach((item) => {
                    if (item.title === value) {
                        this.items = [...(this.items || []), { ...item, type: "document" }]
                    }
                })
            })
        }
    },
}

