
import {
  mdiPlus,
  mdiFileDocument,
  mdiWeb,
  mdiChevronRight,
  mdiChevronLeft,
  mdiClose,
} from '@mdi/js'
import { mapGetters } from 'vuex'
import SourceInputModal from '@/components/feature/components/SourceInputModal.vue'
import FileMin from '@/components/base/FileMin.vue'
import featureComponent from '~/mixins/featureComponent'

export default {
  name: 'SourceSelectModal',
  components: {
    FileMin,
    SourceInputModal,
  },
  mixins: [featureComponent],
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    types: {
      type: Array,
      default: () => ['article', 'document', 'paper'],
    },
  },
  data: () => ({
    modal: false,
    showUrlInput: false,
    title: '',
    subtitle: '',
    type: '',
    file: null,
    icons: {
      mdiPlus,
      mdiFileDocument,
      mdiWeb,
      mdiChevronRight,
      mdiChevronLeft,
      mdiClose,
    },
  }),
  computed: {
    ...mapGetters(['sources']),
    showConfirmButton() {
      return this.multiple && this.model?.length > 0
    },
  },
  watch: {
    sources: {
      handler(newVal) {
        if (!this.model) return
        let m = [...this.model]
        newVal.forEach((source) => {
          m = m.map((s) => {
            if (s.uuid === source.uuid) {
              return source
            }
            return s
          })
        })
        if (JSON.stringify(m) === JSON.stringify(this.model)) return
        this.model = [...m]
      },
      immediate: true,
    },
  },
  methods: {
    submit(source) {
      this.model = source
      this.modal = false
    },
    goBack() {
      this.showUrlInput = false
    },
    openModal() {
      this.modal = true
    },
    isSelected(source) {
      if (this.multiple) {
        if (!Array.isArray(this.model)) {
          return false
        }
        return this.model?.some((s) => s.uuid === source.uuid)
      } else {
        return this.model?.uuid === source.uuid
      }
    },
    onClick(source) {
      if (this.multiple) {
        if (Array.isArray(this.model)) {
            if (this.model.some((s) => s.uuid === source.uuid)) {
                this.model = this.model.filter((s) => s.uuid !== source.uuid)
                
            } else {
                this.model = [...this.model, source]
            }
        } else {
          this.model = [source]
        }
      } else {
        this.submit(source)
      }
    },
  },
}
