
import { mdiWeb, mdiFileSearchOutline, mdiFileUploadOutline } from '@mdi/js'

export default {
  name: 'FileMin',
  /*
    example document:
    
    char_count: 39567
    created_at: "2023-11-28T14:32:36.000000Z"
    id: 1
    page_count: 22
    path: "write/documents/0n0bbpzMCQKQydc50AXEmCsEbUdDHuAPCy2j5OPH.pdf"
    sha1: "4ba72887b96b1caf8086f203185c5bf940fd63f1"
    subtitle: "2.11 MB"
    title: "attention_og_paper.pdf"
    updated_at: "2023-11-28T14:32:36.000000Z"
    user_id: 1
    uuid: "418c3363-617b-4c87-b30d-523cf54f5226"
    word_count: 5577
    */
  props: {
    file: {
      type: Object,
      required: true,
    },
    colored: {
      type: Boolean,
      default: false,
    },
    unclampWidth: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    icons: {
      mdiWeb,
      mdiFileSearchOutline,
      mdiFileUploadOutline,
    },
  }),
  computed: {
    icon() {
      if (this.file.type === 'article') return this.icons.mdiWeb
      if (this.file.type === 'document') return this.icons.mdiFileUploadOutline
      if (this.file.type === 'paper') return this.icons.mdiFileSearchOutline
      return this.icons.mdiFileSearchOutline
    },
    color() {
      if (this.file.type === 'article') return '#2196f3'
      if (this.file.type === 'document') return '#4caf50'
      if (this.file.type === 'paper') return '#ff9800'
      return '#999999'
    },
    processing() {
      return !this.file.is_usable && this.file.type === 'document'
    }
  },
}
