
import { mdiAlertCircle, mdiPlus } from '@mdi/js'
// import RecentSources from '../RecentSources.vue'
import SourceSelectModal from './SourceSelectModal.vue'
import featureComponent from '~/mixins/featureComponent'

export default {
  name: 'SourceInput',
  components: { SourceSelectModal },
  mixins: [featureComponent],
  data: () => ({
    mdiPlus,
    mdiAlertCircle,
  }),
  computed: {
    model: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      },
    },
    chips() {
      if (Array.isArray(this.model)) {
        return this.model
      } else {
        return this.model ? [this.model] : []
      }
    },
    showSelect() {
      if (this.$attrs.multiple) {
        return true
      } else {
        return !this.model
      }
    },
    showHint() {
      if (Array.isArray(this.model)) {
        return this.model.some((s) => s.type === 'document' && !s?.is_usable)
      } else {
        if (this.model) {
          return !this.model?.is_usable && this.model?.type === 'document'
        }
        return false
      }
    },
    filterFn() {
      if (this.$attrs.multiple) {
        return (source) => {
          if (!Array.isArray(this.model)) {
            return this.model?.uuid !== source.uuid
          }
          return !this.model?.some((s) => s.uuid === source.uuid)
        }
      } else {
        return (_) => true
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$emit('validate')
    })
  },
  methods: {
    recentSelect(source) {
      if (this.$attrs.multiple) {
        if (Array.isArray(this.model)) {
          this.model = [
            ...this.model.filter((s) => s.uuid !== source.uuid),
            source,
          ]
        } else {
          this.model = [source]
        }
      } else {
        this.model = source
      }
    },
    trim(str, length) {
      if (!str) return ''
      if (str.length <= length) return str
      return str.substring(0, length) + '...'
    },
    removeChip(index) {
      if (Array.isArray(this.model)) this.model.splice(index, 1)
      else this.model = null
    },
  },
}
